import { useEffect, useMemo, useRef, useState } from "react";

function breakdown(node: React.ReactNode): React.ReactNode[] {
  if (typeof node === "string") {
    return node.split("");
  } else if (Array.isArray(node)) {
    return node.map(breakdown).flat();
  } else {
    return [node];
  }
}

function reassemble(chars: React.ReactNode[]): React.ReactNode {
  const result: React.ReactNode[] = [];
  let stringBuffer = "";
  for (let i = 0; i < chars.length; i++) {
    if (typeof chars[i] === "string") {
      stringBuffer += chars[i];
    } else {
      result.push(stringBuffer);
      stringBuffer = "";
      result.push(chars[i]);
    }
  }
  if (stringBuffer.length > 0) {
    result.push(stringBuffer);
  }
  return result;
}

const DURATION_MS = 2500;
export function TypeIn(props: {
  children: React.ReactNode;
  className?: string;
  debug?: boolean;
}) {
  const children = props.children;
  const changedOnceRef = useRef(false);
  useEffect(() => {
    if (changedOnceRef.current) {
      throw new Error("TypeIn children should not change");
    }
    changedOnceRef.current = true;
  }, [children]);

  const chars = useMemo(() => breakdown(children), [children]);
  const pace = useMemo(() => DURATION_MS / chars.length, [chars]);
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prev) => prev + 1);
    }, pace);
    return () => clearInterval(interval);
  }, [pace]);
  const currentChars = useMemo(
    () => reassemble(chars.slice(0, currentIndex)),
    [chars, currentIndex]
  );

  return (
    <div className={`relative ${props.className}`}>
      <div className="opacity-0">{children}</div>
      <div className="absolute top-0 left-0 w-full">{currentChars}</div>
    </div>
  );
}
