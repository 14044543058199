import { useQuery } from "react-query";
import { skatebowlHealthGET } from "./api";
import { useEffect } from "react";
import * as Sentry from "@sentry/react";

function useSkatebowlHealth() {
  return useQuery<"ok" | "unreachable">({
    queryKey: ["skatebowlHealth"],
    queryFn: skatebowlHealthGET,
    refetchInterval: 10 * 1000,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  });
}

export function SkatebowlHealth() {
  const { data } = useSkatebowlHealth();

  useEffect(() => {
    if (data === "unreachable") {
      Sentry.captureEvent({
        message: "skatebowl health warning shown!",
      });
    }
  }, [data]);

  if (data === "ok" || data === undefined) {
    return null;
  }
  return (
    <div className="flex flex-col text-md items-center justify-center w-full bg-amber-200 py-3 px-4">
      <div>
        skatebowl.com <span className="italic">(the real one)</span> might
        currently be down. some things here might not work... 🙈
      </div>
    </div>
  );
}
