import { useEffect, useRef } from "react";
import { useState } from "react";
import * as Skatebowl from "../shared/skatebowl_types";
import * as Sentry from "@sentry/react";

export const monthToString: { [m: number]: string } = {
  0: "january",
  1: "february",
  2: "march",
  3: "april",
  4: "may",
  5: "june",
  6: "july",
  7: "august",
  8: "september",
  9: "october",
  10: "november",
  11: "december",
};

export const dayToStringLong: { [d: number]: string } = {
  0: "sunday",
  1: "monday",
  2: "tuesday",
  3: "wednesday",
  4: "thursday",
  5: "friday",
  6: "saturday",
};

export const dayToStringShort: { [d: number]: string } = {
  0: "sun",
  1: "mon",
  2: "tue",
  3: "wed",
  4: "thu",
  5: "fri",
  6: "sat",
};

// Date -> 6:30am / 5:00pm format
export function dateToShortTime(date: Date) {
  const h = date.getHours();
  const m = date.getMinutes();
  const ampm = h >= 12 ? "pm" : "am";

  // Add leading zero to minutes if they are less than 10
  const formattedMinutes = m < 10 ? "0" + m : m;

  return `${h % 12 || 12}:${formattedMinutes}${ampm}`;
}

// Date -> "thu @ 6:30am"
export function dateToShortStr(date: Date) {
  return `${dayToStringShort[date.getDay()]} @ ${dateToShortTime(date)}`;
}

export function getOptimalPaymentMethods(
  total: number,
  eligibleBalances: Skatebowl.EligibleBalancesPOST
): Skatebowl.PaymentMethods {
  // Priority: Prepaid Balance -> Credits -> Credit Card
  // Constraint: Cannot pay with multiple types of credits / prepaid balances
  const paymentMethods: Skatebowl.PaymentMethods = [];
  let usedPrepaid = false;

  // Filter for valid balances + sort prepaidBalances by soonest expiration date
  const prepaidBalances = eligibleBalances.prepaidBalances
    .filter((b) => {
      if (b.balance <= 0) {
        return false;
      }

      let { expirationType } = b.fund;
      const { expirationValue } = b.fund;
      expirationType = expirationType.toLowerCase();
      if (expirationType === "never") {
        return true;
      } else if (expirationType === "after # days") {
        // I can't find where # days is sent down. However, there is another
        // field "balancesByExpiration" on the `balance` object that contains a
        // JSON mapping of expiration date -> balance. Worst case, we can use
        // that object if this breaks.
        return true;
      } else if (expirationType === "on specific date") {
        return expirationValue > new Date();
      } else {
        Sentry.captureException({
          message: "unknown expirationType for prepaidBalance",
          tags: {
            expirationType,
          },
        });
      }

      // Unknown value of expirationType, just return true
      return true;
    })
    .sort((a, b) => {
      return a.fund.expirationValue > b.fund.expirationValue ? 1 : -1;
    });

  if (prepaidBalances.length > 0 && total > 0) {
    const p = prepaidBalances[0];
    const amount = Math.min(total, p.balance);
    paymentMethods.push({
      type: "prepaidBalance",
      amount,
      prepaidFundKey: p.fund.objKey,
    });
    usedPrepaid = true;
    total -= amount;
  }

  if (
    // Weirdly enough, creditBalance is stored as a negative number
    eligibleBalances.creditBalance < 0 &&
    total > 0 &&
    !usedPrepaid
  ) {
    const amount = Math.min(total, -eligibleBalances.creditBalance);
    paymentMethods.push({
      type: "credits",
      amount,
    });
    total -= amount;
  }

  if (total > 0) {
    paymentMethods.push({
      type: "creditCard",
      amount: total,
    });
  }

  return paymentMethods;
}

export function assertNever(_x: never): never {
  throw new Error("never");
}

export function useCurrentDate(intervalMs: number) {
  const [date, setDate] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => {
      setDate(new Date());
    }, intervalMs);
    return () => clearInterval(interval);
  }, [intervalMs]);
  return date;
}

export function formatMoney(amount: number) {
  return `$${amount.toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })}`;
}

export function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}
