import React, { useState } from "react";
import { accountGET, useSelectedMemberKey } from "./api";
import { useQuery } from "react-query";
import { useEventListener } from "usehooks-ts";
import { LoadingDots } from "./LoadingDots";
import { Z_INDEXES } from "./constants";

export function AccountMemberInfo() {
  const [expanded, setExpanded] = useState(false);
  const divRef = React.useRef<HTMLDivElement>(null);
  useEventListener("click", (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (!divRef.current?.contains(target)) {
      setExpanded(false);
    }
  });

  const { data, status } = useQuery("accountMembers", async () => {
    return await accountGET();
  });
  const [selectedMemberKey, setSelectedMemberKey] = useSelectedMemberKey();
  if (status !== "success") {
    return <LoadingDots />;
  }

  const members = data.accountMembers;
  const currentMember = members.find((m) => m.objKey === selectedMemberKey);
  const otherMembers = members.filter((m) => m.objKey !== selectedMemberKey);

  const logout = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <div className="relative" ref={divRef}>
      <button className="text-lg" onClick={() => setExpanded((e) => !e)}>
        {currentMember &&
          `it's ${currentMember.person.firstName.toLowerCase()}!`}
      </button>

      {expanded && (
        <div
          className={`absolute top-0 right-0 mt-9 p-4 text-left rounded-lg bg-amber-100 shadow-lg ${Z_INDEXES.MODAL}`}
        >
          {otherMembers.map((m) => (
            <div key={m.objKey}>
              <button onClick={() => setSelectedMemberKey(m.objKey)}>
                i'm {m.person.firstName.toLowerCase()}!
              </button>
              <div className="border-t border-gray-300 my-2"></div>
            </div>
          ))}
          <button onClick={logout}>logout</button>
        </div>
      )}
    </div>
  );
}
