import "./penguin_psst.css";

export default (
  <svg
    className="hop" /*  conditionally apply this */
    width="4em"
    viewBox="0 0 68 68"
    aria-hidden="true"
    role="img"
    preserveAspectRatio="xMidYMid meet"
  >
    <g fill="#e08828">
      <path d="M26.3 60.5c-.8 0-2.6.7-3.2.1c-.8-.8 0-4.6 0-4.6h-2.3s.8 3.8 0 4.6c-.6.5-2.4-.1-3.2-.1c-2 0-3.7 1.5-3.7 1.5h16c.1 0-1.5-1.5-3.6-1.5"></path>

      <path d="M46.3 60.5c-.8 0-2.6.7-3.2.1c-.8-.8 0-4.6 0-4.6h-2.3s.8 3.8 0 4.6c-.6.5-2.4-.1-3.2-.1c-2 0-3.7 1.5-3.7 1.5h16c.1 0-1.5-1.5-3.6-1.5"></path>
    </g>

    <g fill="#3e4347">
      <path
        className="rotateLeft"
        d="M10.4 27C-.4 33.7 1.7 43 3.3 41.9C20 29.7 24.2 18.5 10.4 27z"
      ></path>

      <path
        className="rotateRight"
        d="M53.6 27c-13.8-8.5-9.6 2.7 7.1 14.9c1.6 1.1 3.7-8.2-7.1-14.9"
      ></path>

      <path d="M48 17.5C48 8.9 40.8 2 32 2S16 8.9 16 17.5c0 11.6-4 15.5-4 23.2C12 51.3 21 60 32 60s20-8.7 20-19.3c0-7.7-4-11.6-4-23.2"></path>
    </g>

    <path
      d="M44 18.8c0-12.4-10.1-10-10.1-.7h-3.7C30.1 8.8 20 6.4 20 18.8c0 9.7-4 15.7-4 22.1C16 52.5 23.7 58 32 58s16-5.6 16-17.1c0-6.4-4-12.4-4-22.1"
      fill="#ffffff"
    ></path>

    <g fill="#3e4347">
      <path d="M41 17c0 1.7-.9 3-2 3s-2-1.3-2-3s.9-3 2-3s2 1.3 2 3"></path>

      <path d="M27 17c0 1.7-.9 3-2 3s-2-1.3-2-3s.9-3 2-3s2 1.3 2 3"></path>
    </g>

    <path d="M28 22h8c0 2.5-1.8 4.6-4 4.6s-4-2-4-4.6" fill="#e08828"></path>

    <path
      d="M37 22.3l-5 1.2l-5-1.2c0-3.2 2.2-5.7 5-5.7s5 2.6 5 5.7"
      fill="#f29a2e"
    ></path>

    <g fill="#3e4347">
      <path d="M33.7 17.8c.3.3.4.7.3.9c-.1.2-.5 0-.8-.3c-.3-.3-.4-.7-.3-.9c.2-.1.6 0 .8.3"></path>

      <path d="M30.3 17.8c-.3.3-.4.7-.3.9c.1.2.5 0 .8-.3c.3-.3.4-.7.3-.9c-.2-.1-.6 0-.8.3"></path>
    </g>
  </svg>
);
