import {
  assertNever,
  dayToStringLong,
  formatMoney,
  monthToString,
} from "../lib";
import { Superlative, Wrapped } from "./types";
import penguinMoney from "../penguin_money.gif";
import "./cards.css";
import { TypeIn } from "./TypeIn";
import zamboni from "../penguin_zamboni.png";
import { useEffect, useState } from "react";
import { useAtomValue } from "jotai";
import { wrapped2024ClosingAtom } from "./Wrapped2024";

export function CardContents(props: { children: React.ReactNode }) {
  return (
    <div className={`relative h-full text-center text-2xl`}>
      <div className="flex flex-col items-center h-full justify-center">
        {props.children}
      </div>
    </div>
  );
}

export type WrappedFeature = {
  // contents for primary feature card
  primaryContents: React.ReactNode | null;
  // contents for summary card
  summaryContents: React.ReactNode | null;
  // emoji attached to this feature
  emoji: string | null;
  backsplash: React.ReactNode | null;
};

export function IntroCard() {
  return (
    <CardContents>
      <div className="text-3xl font-bold mt-8 mb-2">2024: shrink-wrapped</div>
      <div className="text-xl mb-8">
        you had an awesome year of skating! let's visit some of your stats ⛸️
      </div>
      <div>
        <img
          className="h-40 mb-8 opacity-85 rounded-lg"
          src={penguinMoney}
          alt="penguin money"
        />
      </div>
    </CardContents>
  );
}

export function considerSessionsCard(data: Wrapped): WrappedFeature {
  const numSessions = data.numSessions;
  const minutes = data.totalMinutes;
  const hours = Math.floor(minutes / 60);
  const summaryContents = (
    <span>
      <span className="font-medium">{numSessions}</span> sessions,{" "}
      <span className="font-medium">{hours}</span> hours skated!
    </span>
  );
  if (numSessions <= 100) {
    return {
      emoji: "⛸️",
      primaryContents: (
        <CardContents key={"sessions"}>
          <TypeIn className="mb-8">
            the rink thanks you for your service ...{" "}
            <span className="font-medium">{numSessions}</span> sessions!
          </TypeIn>
          <TypeIn>
            that's <span className="font-medium">{hours}</span> hours - enough
            time to sing 'frosty the snowman' {Math.floor(minutes / 2.5)} times
            ☃️
          </TypeIn>
        </CardContents>
      ),
      summaryContents,
      backsplash: `${hours} hrs`,
    };
  } else {
    return {
      emoji: "⛸️",
      primaryContents: (
        <CardContents key={"sessions"}>
          <TypeIn className="mb-8">
            your skates might need a vacation after ...{" "}
            <span className="font-medium">{numSessions}</span> sessions!
          </TypeIn>
          <TypeIn>
            that's <span className="font-medium">{hours}</span> hours - enough
            to listen to bolero {Math.floor(minutes / 15)} times 🎶
          </TypeIn>
        </CardContents>
      ),
      summaryContents,
      backsplash: `${hours} hrs`,
    };
  }
}

export function considerHistogramCard(data: Wrapped): WrappedFeature {
  const sortedMonths = data.monthDist
    .map((minutes, monthInd) => ({ minutes, monthInd }))
    .sort((a, b) => b.minutes - a.minutes);
  const monthsTotalMins = sortedMonths.reduce((acc, el) => acc + el.minutes, 0);

  const sortedDays = data.dayOfWeekDist
    .map((minutes, dayInd) => ({ minutes, dayInd }))
    .sort((a, b) => b.minutes - a.minutes);
  const daysTotalMins = sortedDays.reduce((acc, el) => acc + el.minutes, 0);

  if (
    sortedDays[0].minutes / sortedDays[1].minutes >
    sortedMonths[0].minutes / sortedMonths[1].minutes
  ) {
    const maxDay = sortedDays[0];
    const maxDayString = dayToStringLong[maxDay.dayInd];
    const maxHours = Math.floor(maxDay.minutes / 60);
    // assume 5 weekdays. small chance that ends up larger than maxDay.minutes, so constrain it
    const restAverageMinutes = Math.min(
      (daysTotalMins - maxDay.minutes) / (5 - 1),
      maxDay.minutes - 60
    );
    const restAverageHours = Math.floor(restAverageMinutes / 60);
    return {
      emoji: "📆",
      primaryContents: (
        <CardContents key={"histogram"}>
          <TypeIn className="mb-8" debug>
            <span className="font-medium">{maxDayString}</span> came out on top.
            clearly you've got a favorite...
          </TypeIn>
          <TypeIn>
            you skated <span className="font-medium">{maxHours} hours</span> on{" "}
            {maxDayString}s ! that's compared to an average of{" "}
            {restAverageHours} hours on the remaining days 📆
          </TypeIn>
        </CardContents>
      ),
      summaryContents: (
        <span>
          you skated the most on {maxDayString}s, totaling{" "}
          <span className="font-medium">{maxHours}</span> hours
        </span>
      ),
      backsplash: `${maxHours} hrs`,
    };
  } else {
    const maxMonth = sortedMonths[0];
    const maxMonthString = monthToString[maxMonth.monthInd];
    const maxMonthHours = Math.floor(maxMonth.minutes / 60);
    // assume 12 months
    const restAverageMinutes = (monthsTotalMins - maxMonth.minutes) / (12 - 1);
    const restAverageHours = Math.floor(restAverageMinutes / 60);
    return {
      emoji: "📆",
      primaryContents: (
        <CardContents key={"histogram"}>
          <TypeIn className="mb-8">
            <span className="font-medium">{maxMonthString}</span> came out on
            top - had a lot of free time that month?
          </TypeIn>
          <TypeIn>
            you skated{" "}
            <span className="font-medium">{maxMonthHours} hours</span> in{" "}
            {maxMonthString}! that's compared to an average of{" "}
            {restAverageHours} hours in the remaining months 📆
          </TypeIn>
        </CardContents>
      ),
      summaryContents: (
        <span>
          you skated the most in {maxMonthString}, totaling{" "}
          <span className="font-medium">{maxMonthHours}</span> hours
        </span>
      ),
      backsplash: `${maxMonthHours} hrs`,
    };
  }
}

export function considerMoneyCard(data: Wrapped): WrappedFeature {
  const totalCost = Math.floor(data.totalCost);
  const totalCostStr = formatMoney(data.totalCost);
  const totalCreditsUsedStr = formatMoney(data.totalCreditsUsed);
  const summaryContents = (
    <span>
      you spent <span className="font-medium">{totalCostStr}</span>, with{" "}
      <span className="font-medium">{totalCreditsUsedStr}</span> in credits
    </span>
  );
  if (totalCost <= 2000) {
    return {
      emoji: "💰",
      primaryContents: (
        <CardContents key={"money"}>
          <TypeIn className="mb-8">
            putting a squeeze on that credit line... you spent{" "}
            <span className="font-medium">{totalCostStr}</span> this year
          </TypeIn>
          <TypeIn>
            but don't worry, the rink gave you a hand, with{" "}
            <span className="font-medium">{totalCreditsUsedStr}</span> in
            credits 😉
          </TypeIn>
        </CardContents>
      ),
      summaryContents,
      backsplash: `${totalCostStr}`,
    };
  } else {
    return {
      emoji: "💰",
      primaryContents: (
        <CardContents key={"money"}>
          <TypeIn className="mb-8">
            your wallet is officially a memory ... you parted ways with{" "}
            <span className="font-medium">{totalCostStr}</span> in 2024
          </TypeIn>
          <TypeIn>
            but don't worry, the rink gave you a hand, with{" "}
            <span className="font-medium">{totalCreditsUsedStr}</span> in
            credits 😉
          </TypeIn>
        </CardContents>
      ),
      summaryContents,
      backsplash: `${totalCostStr}`,
    };
  }
}

export function considerSuperlativeCard(data: Wrapped): WrappedFeature {
  if (!data.superlative) {
    return {
      emoji: null,
      primaryContents: null,
      summaryContents: null,
      backsplash: null,
    };
  }

  function getSuperlativeContents(superlative: Superlative): {
    emoji: string;
    title: string;
    subtitle: React.ReactNode;
    summary: React.ReactNode;
    backsplash: React.ReactNode;
  } {
    switch (superlative.type) {
      case "AVID_SKATER":
        return {
          emoji: "🥇",
          title: "the avid skater",
          subtitle: (
            <TypeIn>
              you skated{" "}
              <span className="font-medium">{superlative.numSessions}</span>{" "}
              sessions - congrats on a great year!
            </TypeIn>
          ),
          summary: (
            <span>
              you skated{" "}
              <span className="font-medium">{superlative.numSessions}</span>{" "}
              sessions - that makes you the{" "}
              <span className="font-medium">avid skater 🥇</span>
            </span>
          ),
          backsplash: `${superlative.numSessions} sess.`,
        };
      case "WINTER_LOVER":
        return {
          emoji: "❄️",
          title: "the winter fanatic",
          subtitle: (
            <TypeIn>
              you skated{" "}
              <span className="font-medium">{superlative.percent}%</span> of
              your sessions in the winter. congrats on a great year!
            </TypeIn>
          ),
          summary: (
            <span>
              you skated{" "}
              <span className="font-medium">{superlative.percent}%</span> of
              your sessions in the winter - you're quite the{" "}
              <span className="font-medium">winter fanatic ❄️</span>
            </span>
          ),
          backsplash: `${superlative.percent}%`,
        };
      case "AC_SEEKER":
        return {
          emoji: "🥵",
          title: "the ac seeker",
          subtitle: (
            <TypeIn>
              you skated{" "}
              <span className="font-medium">{superlative.percent}%</span> of
              your sessions in the summer. congrats on a great year!
            </TypeIn>
          ),
          summary: (
            <span>
              you skated{" "}
              <span className="font-medium">{superlative.percent}%</span> of
              your sessions in the summer - you're quite the{" "}
              <span className="font-medium">ac seeker 🥵</span>
            </span>
          ),
          backsplash: `${superlative.percent}%`,
        };
      case "DAY_BEFORE":
        return {
          emoji: "🧘‍♀️",
          title: "the zen booker",
          subtitle: (
            <TypeIn>
              you booked{" "}
              <span className="font-medium">{superlative.numDayBefore}</span>{" "}
              sessions a full day in advance - no last-minute chaos!
            </TypeIn>
          ),
          summary: (
            <div>
              you booked{" "}
              <span className="font-medium">{superlative.numDayBefore}</span>{" "}
              sessions a day in advance - you're the{" "}
              <span className="font-medium">zen booker 🧘‍♀️</span>
            </div>
          ),
          backsplash: `${superlative.numDayBefore} sess.`,
        };
      case "LAST_MINUTE":
        return {
          emoji: "⌛️",
          title: "the procrastinator",
          subtitle: (
            <TypeIn>
              you booked{" "}
              <span className="font-medium">{superlative.numLastMinute}</span>{" "}
              sessions just minutes before the session - cutting it close!
            </TypeIn>
          ),
          summary: (
            <div>
              you booked{" "}
              <span className="font-medium">{superlative.numLastMinute}</span>{" "}
              sessions minutes before the session - you've earned the title of{" "}
              <span className="font-medium">procrastinator ⌛️</span>
            </div>
          ),
          backsplash: `${superlative.numLastMinute} sess.`,
        };
      case "LATE_BIRD":
        return {
          emoji: "🦉",
          title: "the afternoon owl",
          subtitle: (
            <TypeIn>
              you skated{" "}
              <span className="font-medium">{superlative.numPostNoon}</span>{" "}
              sessions in the afternoons - better late than never!
            </TypeIn>
          ),
          summary: (
            <span>
              you skated{" "}
              <span className="font-medium">{superlative.numPostNoon}</span>{" "}
              sessions in the afternoon - you're the{" "}
              <span className="font-medium">afternoon owl 🦉</span>
            </span>
          ),
          backsplash: `${superlative.numPostNoon} sess.`,
        };
      case "EARLY_BIRD":
        return {
          emoji: "🐣",
          title: "the early bird",
          subtitle: (
            <TypeIn>
              you skated{" "}
              <span className="font-medium">{superlative.numPre7}</span>{" "}
              sessions before 7am - beating the sun to the rink!
            </TypeIn>
          ),
          summary: (
            <span>
              you skated{" "}
              <span className="font-medium">{superlative.numPre7}</span>{" "}
              sessions before 7am - you're an{" "}
              <span className="font-medium">early bird 🐣</span>
            </span>
          ),
          backsplash: `${superlative.numPre7} sess.`,
        };
      case "DAY_OFTEN":
        const dayString = dayToStringLong[superlative.dayOfWeek];
        return {
          emoji: "📆",
          title: `the ${dayString} devotee`,
          subtitle: (
            <TypeIn>
              you skated{" "}
              <span className="font-medium">{superlative.percent}%</span> of
              your sessions on {dayString}s!
            </TypeIn>
          ),
          summary: (
            <span>
              you're the{" "}
              <span className="font-medium">
                {dayString}{" "}
                <span className="whitespace-nowrap">devotee 📆</span>
              </span>
              , accounting for{" "}
              <span className="font-medium">{superlative.percent}%</span> of
              your sessions
            </span>
          ),
          backsplash: `${superlative.percent}%`,
        };
      default:
        assertNever(superlative);
    }
  }

  const { emoji, title, subtitle, summary, backsplash } =
    getSuperlativeContents(data.superlative);

  return {
    emoji: "🎖️",
    summaryContents: summary,
    primaryContents: (
      <CardContents key={"superlative"}>
        <TypeIn className="text-center mb-8 text-3xl">
          your 2024 honorific is...
          <TypeIn className="text-3xl font-bold w-full">
            {title} <span>{emoji}</span>
          </TypeIn>
        </TypeIn>
        <div className="text-xl">{subtitle}</div>
      </CardContents>
    ),
    backsplash,
  };
}

export function SummaryCard(props: {
  summary: Pick<WrappedFeature, "emoji" | "summaryContents">[];
}) {
  const [showZamboni, setShowZamboni] = useState(false);
  const isClosing = useAtomValue(wrapped2024ClosingAtom);
  useEffect(() => {
    const timeout = setTimeout(() => setShowZamboni(true), 4000);
    return () => clearTimeout(timeout);
  }, []);
  return (
    <CardContents>
      <div className="text-xl pl-3 summary-card">
        <div className="text-left text-2xl font-bold mb-8">
          your 2024: shrink-wrapped
        </div>
        <div className="grid grid-cols-[auto_1fr] gap-x-3 gap-y-3">
          {props.summary.map(({ emoji, summaryContents }) => (
            <>
              <div className="text-lg">{emoji}</div>
              <div className="text-left">{summaryContents}</div>
            </>
          ))}
        </div>
      </div>
      {showZamboni && !isClosing && (
        <img className="zamboni" src={zamboni} alt="zamboni" />
      )}
    </CardContents>
  );
}
