export default (
  <svg
    width="657"
    height="583"
    viewBox="0 0 657 583"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_261_51)">
      <path
        d="M24 129.471C65.0072 58.4443 157.627 33.9928 228.654 75C299.68 116.007 310.259 230.656 297.695 294.417C280.995 379.173 312.089 457.457 383.116 498.464C454.142 539.471 558.467 532.747 599.474 461.721"
        stroke="#E5FBFF"
        stroke-width="6"
      />
    </g>
  </svg>
);
