export const Z_INDEXES = {
  BASE: "z-[0]",
  PSST_PENGUIN: "z-[5]",
  DAY_SLOT: "z-[10]",

  MODAL_BACKGROUND: "z-[45]",
  WRAPPED_ICE_TRACE: "z-[46]",
  MODAL: "z-[50]",

  WRAPPED_TAP_TARGETS: "z-[55]",
};
