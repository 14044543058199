import { useAuthenticate } from "./api";
import { QuickBook } from "./QuickBook";
import { Header, Page } from "./components";
import { Schedule } from "./Schedule";
import { Maintenance } from "./Maintenance";
import { createPortal } from "react-dom";
import { BookingModal, bookingModalInfoAtom } from "./BookingModal";
import { useAtomValue } from "jotai";
import { GeneralDisclaimer } from "./Disclaimer";
import { useEffect, useState } from "react";
import { AccountMemberInfo } from "./AccountMemberInfo";
import { useWrapped2024StepAtom, Wrapped2024 } from "./wrapped2024/Wrapped2024";
import shrinkWrap from "./shrink_wrap.png";
import { SkatebowlHealth } from "./SkatebowlHealth";

const inMaintenance = false;
function App() {
  useAuthenticate();
  const [pageElement, setPageElement] = useState<HTMLElement | null>(null);
  useEffect(() => {
    setPageElement(document.getElementById("page"));
  }, []);

  const bookingModalInfo = useAtomValue(bookingModalInfoAtom);
  const [wrapped2024Step] = useWrapped2024StepAtom();
  if (inMaintenance) {
    return (
      <Page>
        <Maintenance />
      </Page>
    );
  }

  return (
    <Page>
      {pageElement && createPortal(<GeneralDisclaimer />, pageElement)}
      {Array.from({ length: 10 }).map((_, i) => (
        <div key={i} className="snow">
          {wrapped2024Step === 0 ? (
            <img
              src={shrinkWrap}
              alt="snowflake"
              style={{
                transform: `rotate(${(i * 777) % 360}deg)`,
                height: `36px`,
              }}
            />
          ) : (
            "•"
          )}
        </div>
      ))}
      <SkatebowlHealth />
      <Header right={<AccountMemberInfo />} />
      <QuickBook />
      <div className="mb-4" />
      <Schedule />
      {bookingModalInfo &&
        pageElement &&
        createPortal(<BookingModal />, pageElement)}
      {wrapped2024Step !== null &&
        pageElement &&
        createPortal(<Wrapped2024 />, pageElement)}
    </Page>
  );
}

export default App;
