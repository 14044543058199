export default (
  <svg
    width="530"
    height="583"
    viewBox="0 0 530 583"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_268_80)">
      <path
        d="M38.3616 159.819C38.3616 159.819 182.004 95.8525 316.211 221.804C316.211 221.804 339.439 235.716 364.462 229.225C359.57 254.608 379.12 280.844 379.12 280.844C513.327 406.795 448.686 549.636 448.686 549.636"
        stroke="#E5FBFF"
        stroke-width="6"
        stroke-linecap="round"
      />
    </g>
  </svg>
);
