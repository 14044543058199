import { useState } from "react";
import {
  multiClassTimeSlotsPOST,
  useAllBookedMeetings,
  useBookMeetingMutation,
} from "./api";
import * as Skatebowl from "../shared/skatebowl_types";
import { Section } from "./components";
import penguin from "./penguin_money.gif";
import { useLocalStorage } from "usehooks-ts";
import { BookButton } from "./BookButton";

export async function findSession(
  bookDate: Date,
  alreadyBookedMeetingKeysPromise: Promise<string[]>
) {
  const [timeSlots, alreadyBookedMeetingKeys] = await Promise.all([
    multiClassTimeSlotsPOST(bookDate.getDay()),
    alreadyBookedMeetingKeysPromise,
  ]);
  const slot = timeSlots.find((slot) =>
    // HH:MM format
    {
      const matchesTime =
        slot.startTime === bookDate.toTimeString().slice(0, 5);
      const matchesDate = slot.meetings.some(
        (m) =>
          new Date(m.meeting.date + " 00:00").toDateString() ===
          bookDate.toDateString()
      );
      return matchesTime && matchesDate;
    }
  );
  if (!slot) {
    throw new Error(`No slot found for ${bookDate.toLocaleString()}`);
  }
  // Find the right session day given the time slot
  const foundSession = slot.meetings.find(
    (m) =>
      new Date(
        // Force into PST
        m.meeting.date + " 00:00"
      ).toDateString() === bookDate.toDateString()
  );
  if (!foundSession) {
    throw new Error(`No session found for ${bookDate.toLocaleString()}`);
  }

  if (
    alreadyBookedMeetingKeys.lastIndexOf(foundSession.meeting.objKey) !== -1
  ) {
    throw new Error(`Already booked ${bookDate.toLocaleString()}`);
  }

  return {
    ...foundSession,
    date: bookDate,
  };
}

// Get time components from HH:MMam/pm string in 24-hour format
function parseTimeString(time: string) {
  const [_h, rest] = time.split(":");
  const m = Number.parseInt(rest.slice(0, 2));
  const ampm = rest.slice(2);
  const h = Number.parseInt(_h) + (ampm === "pm" ? 12 : 0);
  return { h, m };
}

// Given the chosen time (i.e. 7:45am), infer what day the session we want to
// book, is on. Currently this just chooses the day corresponding to the next
// weekday that chosenTime occurs.
export function inferSessionDate(
  // HH:MM
  chosenTime: string
): Date {
  const { h, m } = parseTimeString(chosenTime);
  const currentTime = new Date();
  // starts as targetTime on today's date
  const targetTimeToday = new Date(
    currentTime.getFullYear(),
    currentTime.getMonth(),
    currentTime.getDate(),
    h,
    m
  );

  let nextOccurrence: Date;
  if (currentTime < targetTimeToday) {
    // targetTime is yet to happen today, fast-forward to it
    nextOccurrence = targetTimeToday;
  } else {
    // targetTime has already happened today, fast-forward to tomorrow
    nextOccurrence = targetTimeToday;
    nextOccurrence.setDate(nextOccurrence.getDate() + 1);
  }

  // Skip weekends
  while (nextOccurrence.getDay() === 0 || nextOccurrence.getDay() === 6) {
    nextOccurrence.setDate(nextOccurrence.getDate() + 1);
  }

  return nextOccurrence;
}

const allTimes = ["6:00am", "7:45am", "9:00am", "3:45pm", "4:45pm"];
// Schema: unique array of most-recent booking times
function useRecentTimes() {
  const [recentBookings] = useLocalStorage<string[]>("recentBookings", []);
  // Seed with 7:45 and 9:00 times
  if (!recentBookings.includes("7:45am")) {
    recentBookings.push("7:45am");
  }
  if (!recentBookings.includes("9:00am")) {
    recentBookings.push("9:00am");
  }
  // Sort in chronological order
  return [...recentBookings].slice(0, 2).sort((t1, t2) => {
    const { h: h1, m: m1 } = parseTimeString(t1);
    const { h: h2, m: m2 } = parseTimeString(t2);
    return h1 - h2 || m1 - m2;
  });
}
export function QuickBook() {
  // Prefetch for quick book use
  useAllBookedMeetings();
  const [expanded, setExpanded] = useState(false);
  const recentTimes = useRecentTimes();
  const times = expanded ? allTimes : recentTimes;
  const bookMutation = useBookMeetingMutation();

  return (
    <>
      <Section>
        <div className="flex flex-row items-center justify-between w-full mb-4">
          <h2 className="text-slate-600 text-lg font-light">quick book</h2>
          <button
            className="text-slate-500 text-sm ml-2 underline decoration-dotted"
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? "show less" : "show more!"}
          </button>
        </div>
        <div className="mb-4">
          {bookMutation.isLoading ? (
            <img
              className="w-20 h-20 opacity-80 rounded"
              src={penguin}
              alt="penguin money"
            />
          ) : bookMutation.isSuccess ? (
            <div className="flex flex-col items-center">
              <div>successfully booked! 🎉</div>
              <PaymentMethodsInfo paymentMethods={bookMutation.data} />
            </div>
          ) : (
            <div className="grid grid-cols-2 gap-4">
              {times.map((time) => (
                <BookButton
                  disabled={bookMutation.isLoading}
                  time={time}
                  key={time}
                  book={(args) => bookMutation.mutate(args)}
                />
              ))}
            </div>
          )}
        </div>
      </Section>
    </>
  );
}

export function PaymentMethodsInfo(props: {
  paymentMethods: Skatebowl.PaymentMethods;
}) {
  const prepaidPaymentsAmount = props.paymentMethods.reduce((acc, e) => {
    if (e.type === "prepaidBalance") {
      return acc + e.amount;
    } else {
      return acc;
    }
  }, 0);
  const creditsAmount =
    props.paymentMethods.find((p) => p.type === "credits")?.amount || 0;

  // If 100% credit card, don't show any info text
  if (prepaidPaymentsAmount === 0 && creditsAmount === 0) {
    return null;
  }

  const strs: string[] = [];
  if (prepaidPaymentsAmount > 0) {
    strs.push(`$${prepaidPaymentsAmount} in prepaid payments`);
  }
  if (creditsAmount > 0) {
    strs.push(`$${creditsAmount} in credits`);
  }

  return <div>used {strs.join(" + ")} 🤑</div>;
}
