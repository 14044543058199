import React from "react";
import { useLocalStorage } from "usehooks-ts";
import { authStateAtom, authenticate } from "./api";
import { Header, Section, Input, Page, Button } from "./components";
import { useMutation } from "react-query";
import penguin from "./penguin_rocket.gif";
import { useAtomValue } from "jotai";
import { navigate } from ".";
import { SkatebowlHealth } from "./SkatebowlHealth";

export function Auth() {
  const [username, setUsername] = useLocalStorage("username", "");
  const [password, setPassword] = useLocalStorage("password", "");
  const authMutation = useMutation({
    mutationFn: () => {
      return authenticate("/");
    },
  });
  const authState = useAtomValue(authStateAtom);
  React.useEffect(() => {
    if (authState !== null) {
      navigate("/");
    }
  }, [authState]);

  return (
    <Page>
      <SkatebowlHealth />
      <Header />
      <Section>
        <form
          onSubmit={() => authMutation.mutate()}
          className="w-full flex flex-col items-center"
        >
          <div className="mb-4 w-full">
            <Input
              placeholder="username"
              value={username}
              setValue={setUsername}
            />
          </div>
          <div className="mb-4 w-full">
            <Input
              placeholder="password"
              value={password}
              setValue={setPassword}
              type="password"
            />
          </div>
          {authMutation.isLoading ? (
            <img
              className="w-20 h-20 opacity-80 rounded absolute -bottom-2"
              src={penguin}
              alt="penguin rocket"
            />
          ) : (
            <Button
              onClick={() => authMutation.mutate()}
              textClass="text-xl"
              fullWidth
            >
              login
            </Button>
          )}
        </form>
      </Section>

      <div className="my-10"></div>
      <Section>
        <h2 className="w-full text-lg mb-3">how do i use this?</h2>
        <div className="w-full text-lg">
          <div className="grid grid-cols-[auto_1fr] gap-x-3 gap-y-3">
            <div className="text-lg">❄️</div>
            <div>
              login with your skatebowl.com info, and book ice sessions
              directly!
            </div>
            <div className="text-lg">🎄</div>
            <div>
              we don't store any of your login or payment info — we just talk to
              skatebowl.com on your behalf.
            </div>
            <div className="text-lg">☃️</div>
            <div>
              skeetbowl is non-official + unaffiliated with the rink! support
              may be limited.
            </div>
          </div>
        </div>
      </Section>
    </Page>
  );
}
