export default (
  <svg
    width="540"
    height="583"
    viewBox="0 0 540 583"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_268_88)">
      <path
        d="M37.2633 467.888C104.878 533.849 173.08 544.759 237.479 498.802C299.82 454.314 318.369 382.749 279.78 309.174C279.78 309.174 278.418 291.092 290.952 279.986C257.017 281.045 258.434 264.358 258.434 264.358C214.478 193.858 239.652 132.545 300.994 86.6903C364.486 39.2281 425.184 47.0167 497.52 107.764"
        stroke="#E5FBFF"
        stroke-width="6"
        stroke-linecap="round"
      />
    </g>
  </svg>
);
