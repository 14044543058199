import React, { useCallback, useEffect, useMemo } from "react";
import * as Sentry from "@sentry/react";
import { authStateAtom, transactionsGET } from "../api";
import * as Skatebowl from "../../shared/skatebowl_types";
import _ from "lodash";
import { atom, useAtom, useAtomValue } from "jotai";
import { Superlative, Wrapped } from "./types";
import {
  considerHistogramCard,
  considerMoneyCard,
  considerSessionsCard,
  considerSuperlativeCard,
  IntroCard,
  SummaryCard,
  WrappedFeature,
} from "./Cards";

import xIcon from "../x.svg";
import "./wrapped2024.css";
import { Z_INDEXES } from "../constants";
import { useQuery } from "react-query";
import { useCurrentDate } from "../lib";
import iceTrace1 from "../ice_trace_1";
import iceTrace2 from "../ice_trace_2";
import iceTrace3 from "../ice_trace_3";
import iceTrace4 from "../ice_trace_4";

type WrappedDebug = Wrapped & DebugOnly;
type DebugOnly = {
  applicableSuperlatives: Superlative[];
};

function inferAMPM(_ampm: string): string {
  const ampm = _ampm.toLowerCase();
  if (ampm.includes("a")) return "am";
  if (ampm.includes("p")) return "pm";
  return "am";
}

// time can be "9:00", or "745", or "445"
function inferTime(time: string, _ampm: string): { h: number; m: number } {
  const ampm = inferAMPM(_ampm);
  if (time.includes(":")) {
    const [h, m] = time.split(":");
    return {
      h: ampm === "am" ? parseInt(h) : parseInt(h) + 12,
      m: parseInt(m),
    };
  } else {
    const h = parseInt(time.slice(0, -2));
    const m = parseInt(time.slice(-2));
    return {
      h: ampm === "am" ? h : h + 12,
      m,
    };
  }
}

function parseDescription(description: string): {
  start: Date;
  duration: number;
} | null {
  let startDate: Date | null = null;
  // match "1/2/2024"
  const regexDate1 = /\b(\d+\/\d+\/\d+)/;
  // match "1/8"
  const regexDate2 = /\b(\d+\/\d+)/;
  const dateMatch1 = description.match(regexDate1);
  const dateMatch2 = description.match(regexDate2);
  if (dateMatch1) {
    const [_, date] = dateMatch1;
    startDate = new Date(date);
  } else if (dateMatch2) {
    const [_, date] = dateMatch2;
    startDate = new Date(`${date}/2024`);
  } else {
    // console.error("Invalid session date: " + description);
    return null;
  }
  // (7:45a 60-Min Fri)
  const regex1 = /\((\d+:\d+)([ap]) (\d+)-Min (\w+)\s*\)/;
  // (7:45a 60-Min Fri)
  const regex2 = /\((\d+)([ap]) (\d+)-Min (\w+)\s*\)/;
  // Thursday 7:45AM to 8:45AM
  const regex3 = /(\w+) (\d+:\d+(?:AM)|(?:PM)) to (\d+:\d+(?:AM)|(?:PM))/;
  const match1 = description.match(regex1);
  const match2 = description.match(regex2);
  const match3 = description.match(regex3);
  let duration: number | null = null;
  if (match1) {
    const [_, time, ampm, _duration] = match1;
    const { h, m } = inferTime(time, ampm);
    startDate.setHours(h, m);
    duration = parseInt(_duration);
  } else if (match2) {
    const [_, time, ampm, _duration] = match2;
    const { h, m } = inferTime(time, ampm);
    startDate.setHours(h, m);
    duration = parseInt(_duration);
  } else if (match3) {
    const [_, __, start, end] = match3;
    const { h: h1, m: m1 } = inferTime(start.slice(0, -2), start.slice(-2));
    const { h: h2, m: m2 } = inferTime(end.slice(0, -2), end.slice(-2));
    startDate.setHours(h1, m1);
    duration = (h2 - h1) * 60 + (m2 - m1);
  } else {
    // console.error("Invalid session description: " + description);
    return null;
  }

  return {
    start: startDate,
    duration,
  };
}

function computeSuperlative(
  wrapped: Wrapped,
  userHash: string
): [Superlative | null, DebugOnly] {
  if (wrapped.numSessions <= 10) {
    return [null, { applicableSuperlatives: [] }];
  }

  const applicable: Superlative[] = [];

  // weight scale: 100 max ish
  if (wrapped.numPre7 / wrapped.numSessions >= 0.35) {
    applicable.push({
      type: "EARLY_BIRD",
      numPre7: wrapped.numPre7,
      weight: (wrapped.numPre7 / wrapped.numSessions) * 100 * 2,
    });
  } else if (wrapped.numPostNoon / wrapped.numSessions >= 0.17) {
    applicable.push({
      type: "LATE_BIRD",
      numPostNoon: wrapped.numPostNoon,
      weight: (wrapped.numPostNoon / wrapped.numSessions) * 200 * 2,
    });
  }

  if (wrapped.numLastMinute / wrapped.numSessions >= 0.2) {
    applicable.push({
      type: "LAST_MINUTE",
      numLastMinute: wrapped.numLastMinute,
      weight: (wrapped.numLastMinute / wrapped.numSessions) * 100 * 3,
    });
  } else if (wrapped.numDayBefore / wrapped.numSessions >= 0.2) {
    applicable.push({
      type: "DAY_BEFORE",
      numDayBefore: wrapped.numDayBefore,
      weight: (wrapped.numDayBefore / wrapped.numSessions) * 75 * 3,
    });
  }

  if (wrapped.numSessions >= 149) {
    applicable.push({
      type: "AVID_SKATER",
      numSessions: wrapped.numSessions,
      weight: wrapped.numSessions / 5,
    });
  }

  // 0 = January
  const WINTER_MONTHS = [11, 0, 1];
  const SUMMER_MONTHS = [5, 6, 7];
  const winterMinutes = _.sum(
    wrapped.monthDist.filter((_, i) => WINTER_MONTHS.includes(i))
  );
  const summerMinutes = _.sum(
    wrapped.monthDist.filter((_, i) => SUMMER_MONTHS.includes(i))
  );
  const totalMinutes = _.sum(wrapped.monthDist);
  if (winterMinutes / totalMinutes >= 0.35) {
    applicable.push({
      type: "WINTER_LOVER",
      percent: Math.round((winterMinutes / totalMinutes) * 100),
      weight: (winterMinutes / totalMinutes) * 100 * 0.7,
    });
  }
  if (summerMinutes / totalMinutes >= 0.35) {
    applicable.push({
      type: "AC_SEEKER",
      percent: Math.round((summerMinutes / totalMinutes) * 100),
      weight: (summerMinutes / totalMinutes) * 100 * 0.7,
    });
  }

  const dayOfWeekMax = wrapped.dayOfWeekDist.indexOf(
    Math.max(...wrapped.dayOfWeekDist)
  );
  applicable.push({
    type: "DAY_OFTEN",
    dayOfWeek: dayOfWeekMax,
    percent: Math.round(
      (wrapped.dayOfWeekDist[dayOfWeekMax] / _.sum(wrapped.dayOfWeekDist)) * 100
    ),
    // don't choose DAY_OFTEN unless it's the only option
    weight: 0.01,
  });

  const sorted = _.sortBy(applicable, (s) => s.weight);

  // Algorithm: If highest/second-highest are within 10% of each other, pick at
  // random between them. Otherwise, pick the highest.
  const highest = sorted[sorted.length - 1];
  const secondHighest = sorted[sorted.length - 2];

  if (!highest && !secondHighest) {
    return [null, { applicableSuperlatives: applicable }];
  } else if (!secondHighest || highest.weight / secondHighest.weight > 1.1) {
    return [highest, { applicableSuperlatives: applicable }];
  }

  const hash = (s: string) => {
    let sum = 0;
    for (let i = 0; i < s.length; i++)
      sum += ((i + 1) * s.codePointAt(i)!) / (1 << 8);
    return sum % 1;
  };
  const hash0to1 = hash(userHash);
  return [
    hash0to1 < 0.5 ? highest : secondHighest,
    { applicableSuperlatives: [] },
  ];
}

function computeWrappedData(
  transactions: Skatebowl.TransactionGETResponse,
  uniqueUserHash: string
): Wrapped {
  const wrapped: Wrapped = {
    numSessions: 0,
    numPre7: 0,
    numPostNoon: 0,
    totalMinutes: 0,
    totalCost: 0,
    totalCreditsUsed: 0,
    numLastMinute: 0,
    numDayBefore: 0,
    superlative: null,
    dayOfWeekDist: Array(7).fill(0),
    monthDist: Array(12).fill(0),
  };
  for (const transaction of transactions) {
    const { id, totalAmount, items, transactionTime } = transaction;
    // Filter out transactions not in 2024
    if (
      new Date(transactionTime) < new Date("2024-01-01") ||
      new Date(transactionTime) >= new Date("2025-01-01")
    ) {
      continue;
    }
    if (id.includes("Shop")) {
      // Hard to parse these
      continue;
    } else if (items.length === 0) {
      // console.error("No items in transaction:", transaction);
      continue;
    } else if (items.length > 1) {
      // console.error("Multiple items in transaction:", transaction);
      continue;
    }

    const { description } = items[0];
    const sessionDetails = parseDescription(description);
    // Successfully parsed a session - rack up the stats
    if (!sessionDetails) {
      continue;
    }
    wrapped.numSessions++;
    wrapped.totalMinutes += sessionDetails.duration;
    wrapped.totalCost += totalAmount;
    if (sessionDetails.start.getHours() < 7) {
      wrapped.numPre7++;
    } else if (sessionDetails.start.getHours() >= 12) {
      wrapped.numPostNoon++;
    }
    const diffMins =
      (sessionDetails.start.getTime() - new Date(transactionTime).getTime()) /
      (1000 * 60);
    if (diffMins <= 10) {
      wrapped.numLastMinute++;
    } else if (diffMins >= 60 * 24) {
      wrapped.numDayBefore++;
    }
    const dayOfWeek = sessionDetails.start.getDay();
    const month = sessionDetails.start.getMonth();
    wrapped.dayOfWeekDist[dayOfWeek] += sessionDetails.duration;
    wrapped.monthDist[month] += sessionDetails.duration;

    transaction.payments.forEach((payment) => {
      if (
        payment.paymentMethod === "PrepaidFunds" ||
        payment.paymentMethod === "ApplyCredit"
      ) {
        wrapped.totalCreditsUsed += Math.abs(payment.amount);
      }
    });
  }

  const [superlative, debug] = computeSuperlative(wrapped, uniqueUserHash);
  wrapped.superlative = superlative;
  const wrappedDebug: WrappedDebug = {
    ...wrapped,
    ...debug,
  };
  Sentry.captureEvent({
    message: "2024 wrapped debug",
    tags: {
      ...wrappedDebug,
      dayOfWeekDist: JSON.stringify(wrappedDebug.dayOfWeekDist),
      monthDist: JSON.stringify(wrappedDebug.monthDist),
      superlative: JSON.stringify(wrappedDebug.superlative),
      applicableSuperlatives: JSON.stringify(
        wrappedDebug.applicableSuperlatives.map((s) => ({
          type: s.type,
          weight: s.weight,
        }))
      ),
    },
  });
  return wrappedDebug;
}

function computeWrappedCards(
  transactions: Skatebowl.TransactionGETResponse,
  uniqueUserHash: string
): {
  contents: React.ReactNode;
  backsplash?: React.ReactNode;
  iceTrace?: boolean;
}[] {
  const data = computeWrappedData(transactions, uniqueUserHash);
  const features: WrappedFeature[] = [];
  features.push(considerSessionsCard(data));
  features.push(considerHistogramCard(data));
  features.push(considerMoneyCard(data));
  features.push(considerSuperlativeCard(data));
  const summaries = features
    .map((f) => ({
      emoji: f.emoji,
      summaryContents: f.summaryContents,
    }))
    .filter((b) => b.summaryContents !== null && b.emoji !== null);
  const cards = [
    { contents: <IntroCard /> },
    ...features
      .map((f) => ({
        contents: f.primaryContents,
        backsplash: f.backsplash,
        iceTrace: true,
      }))
      .filter((a) => a.contents !== null),
    {
      contents: <SummaryCard summary={summaries} />,
      backsplash: "2024  ",
      iceTrace: true,
    },
  ];
  return cards;
}

const wrapped2024StepAtom = atom<number | null>(null);
export const wrapped2024ClosingAtom = atom<boolean>(false);
export function useWrapped2024StepAtom() {
  const [step, setStep] = useAtom(wrapped2024StepAtom);
  const [, setClosing] = useAtom(wrapped2024ClosingAtom);
  const wrappedSet = useCallback(
    (newStep: number | null) => {
      // If closing, add a small delay
      if (newStep === null) {
        setClosing(true);
        setTimeout(() => {
          setClosing(false);
          setStep(null);
        }, 500);
      } else {
        setStep(newStep);
      }
    },
    [setStep, setClosing]
  );
  return [step, wrappedSet] as const;
}

export function useWrappedEnabled() {
  const mobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  const date = useCurrentDate(60 * 1000);
  const authState = useAtomValue(authStateAtom);
  const isLeslie =
    authState?.user.firstName.toLowerCase() === "leslie" ||
    authState?.user?.email.toLowerCase() === "leslie.duskin@gmail.com";
  return mobile && isLeslie && date < new Date("2025-02-01");
}

export function useWrapped(enabled?: boolean) {
  const result = useQuery({
    queryKey: ["transactions"],
    queryFn: transactionsGET,
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled,
  });
  const authState = useAtomValue(authStateAtom);
  const clientAccountKey =
    authState?.environmentInfo.userProxy.clientAccountKey;
  return useMemo(() => {
    if (result.data === undefined || clientAccountKey === undefined) {
      return undefined;
    }
    return computeWrappedCards(result.data, clientAccountKey);
  }, [clientAccountKey, result.data]);
}

const iceTraces = [iceTrace1, iceTrace2, iceTrace3, iceTrace4];

export function Wrapped2024() {
  const [step, setStep] = useWrapped2024StepAtom();
  const wrappedCards = useWrapped(step !== null);
  const isClosing = useAtomValue(wrapped2024ClosingAtom);

  useEffect(() => {
    document.documentElement.style.overflow = "hidden";
    return () => {
      document.documentElement.style.overflow = "";
    };
  }, []);

  const rotateAng = useMemo(() => {
    void step;
    const mag = Math.random() * 15 + 15;
    return Math.random() < 0.5 ? -mag : mag;
  }, [step]);

  if (
    wrappedCards === undefined ||
    wrappedCards.length === 0 ||
    step === null
  ) {
    return null;
  }

  const bgColor =
    step % 4 === 0
      ? "bg-cyan-100"
      : step % 4 === 1
      ? "bg-purple-100"
      : step % 4 === 2
      ? "bg-orange-100"
      : "bg-pink-100";

  return (
    <>
      <div
        className={`container ${isClosing ? "slideOut" : ""}
          ${Z_INDEXES.MODAL_BACKGROUND}
          ${bgColor}`}
        onKeyDown={(e) => {
          if (e.key === "ArrowLeft") {
            setStep(Math.max(0, step - 1));
          } else if (e.key === "ArrowRight") {
            setStep(Math.min(wrappedCards.length - 1, step + 1));
          }
        }}
        tabIndex={0}
      >
        <div
          className={`tapTarget left ${Z_INDEXES.WRAPPED_TAP_TARGETS}`}
          onClick={() => setStep(Math.max(0, step - 1))}
        />
        <div
          className={`tapTarget right ${Z_INDEXES.WRAPPED_TAP_TARGETS}`}
          onClick={() => setStep(Math.min(wrappedCards.length - 1, step + 1))}
        />

        {/* Put a display X button on a lower z-index, while the actual interaction is on the correct, higher z-index. This lets the zamboni
        run over the display X button */}
        <button
          className={`absolute opacity-0 top-9 right-6 text-slate-300 font-bold p-3 ${Z_INDEXES.WRAPPED_TAP_TARGETS}`}
          onClick={() => setStep(null)}
        >
          <div className="w-4 h-4 opacity-0">.</div>
        </button>
        <div
          className={`absolute top-9 right-6 text-slate-300 font-bold p-3 ${Z_INDEXES.MODAL_BACKGROUND}`}
        >
          <img src={xIcon} alt="close" className="w-4 h-4" />
        </div>

        <div className={`topBar ${Z_INDEXES.MODAL}`}>
          <div className="progressBarContainer">
            {Array.from({ length: wrappedCards.length }).map((_, i) => (
              <div
                key={i}
                className={`progressBar ${i <= step ? "filled" : ""}`}
              />
            ))}
          </div>
        </div>

        <div
          className={`content relative w-full max-w-2xl h-full text-slate-600 flex flex-col p-8 mb-20 ${Z_INDEXES.MODAL}`}
          onClick={(e) => e.stopPropagation()}
        >
          {wrappedCards[step].contents}
        </div>
        <div className="absolute flex flex-col items-center justify-center w-full h-full">
          {wrappedCards[step].iceTrace && (
            <div className={`absolute ice-trace`} key={step % 4}>
              {iceTraces[step % 4]}
            </div>
          )}
          {!isClosing && (
            <div
              className="text-marquee absolute text-[200px] font-medium opacity-10 w-full text-center whitespace-nowrap"
              style={{
                transform: `rotate(${rotateAng}deg)`,
              }}
            >
              <span>
                {wrappedCards[step].backsplash}&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
              <span>
                {wrappedCards[step].backsplash}&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
              <span>
                {wrappedCards[step].backsplash}&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
              <span>
                {wrappedCards[step].backsplash}&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
