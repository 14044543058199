export default (
  <svg
    width="540"
    height="583"
    viewBox="0 0 540 583"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M37.2632 467.888C104.878 533.849 173.08 544.759 237.479 498.802C299.82 454.314 318.368 382.749 279.78 309.174C279.78 309.174 269.48 291.024 253 294C274.828 267.994 263.5 262.5 263.5 262.5C219.544 192 263.5 114.718 300.994 86.6903C364.486 39.2279 425.184 47.0168 497.52 107.764"
      stroke="#E5FBFF"
      stroke-width="4"
      stroke-linecap="round"
    />
  </svg>
);
