import { useLocalStorage } from "usehooks-ts";
import { Button } from "./components";
import { Z_INDEXES } from "./constants";

const LOCAL_STORAGE_KEY = "showDisclaimer";
export function GeneralDisclaimer() {
  const [show, setShow] = useLocalStorage(LOCAL_STORAGE_KEY, true);
  if (!show) {
    return null;
  }

  return (
    <>
      {/* background */}
      <div
        className={`absolute h-screen w-full bg-slate-700 opacity-50 ${Z_INDEXES.MODAL_BACKGROUND}`}
      ></div>
      <div
        className={`absolute w-full h-full flex flex-col justify-center items-center ${Z_INDEXES.MODAL}`}
      >
        <div
          className="rounded-xl w-4/5 max-w-sm bg-purple-100 text-slate-600 flex flex-col p-8"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="text-xl mb-5">hi there! 👋</div>
          <div className="mb-3">
            skeetbowl is a non-official app, with no affiliation with the rink.
            there's little support available in case of issues with bookings or
            payments -- please use at your own discretion.
          </div>
          <div className="mb-4">⛸️ happy skating!</div>

          <div className="flex justify-center">
            <Button onClick={() => setShow(false)}>understood!</Button>
          </div>
        </div>
      </div>
    </>
  );
}
